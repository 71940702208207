.content {
  display: flex;
  justify-content: center;
  padding-right: var(--gap-small);

  @media (--phone) {
    flex-direction: column-reverse;
    padding: 0;
  }
}

.name {
  color: var(--color-primary-400);
  font-size: var(--text-3xl);
}

.imageContainer {
  height: 418px;
  max-height: 30vw;
  position: relative;
  width: 70%;

  @media (--tablet-) {
    max-height: none;
  }

  @media (--phone) {
    height: 300px;
    margin: auto;
    text-align: center;
  }
}

.image {
  object-fit: contain;
  object-position: bottom;
}

.info {
  margin-top: var(--gap-title);
  text-align: left;

  @media (--tablet) {
    width: 206px;
  }

  @media (--phone) {
    margin: 0;
    margin-bottom: var(--gap-title);
    padding-top: 0;
    text-align: center;
  }
}

.address {
  font-size: var(--text-m);
  margin: 0 auto;

  @media (--phone) {
    justify-content: center;
  }
}

.subtitle {
  color: var(--color-neutral-600);
  font-size: var(--text-m);
  margin-bottom: var(--gap-small);
}

.globalLink {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
