.list {
  list-style: none;
  margin: 0;
  padding: var(--gap-tiny) 0;
}

.listItem {
  align-items: baseline;
  display: flex;

  &:not(:last-child) {
    margin-bottom: var(--gap-tiny);
  }
  &:not(:first-child)::before {
    background-image: url('~/icons/accent-list-arrow.svg');
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }
}

.link {
  cursor: default;
  font-size: inherit;
  line-height: 1.7em;
  text-decoration: none;
}

.homePageLink {
  font-size: inherit;
  line-height: 1.7em;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: var(--color-accent-primary);
  }
}
