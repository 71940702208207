.address {
  align-items: center;
  display: flex;
  font-size: var(--text-m);
  font-style: normal;
  margin-top: var(--gap-small);

  &::before {
    background-image: url('../../icons/pin.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    flex-shrink: 0;
    height: rem(16px);
    margin-right: 12px;
    width: rem(15px);
  }
}
