@keyframes showDialog {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.container {
  animation: showDialog 0.3s ease-in-out forwards;
  background-color: var(--color-primary-500);
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  left: 0;
  position: fixed;
  right: 0;
  transform: translateY(100%);
  z-index: var(--z-index-modal);
}

.wrapper {
  align-items: center;
  color: var(--color-neutral-0);
  display: flex;
  max-height: 100vh;
  padding-bottom: var(--gap);

  padding-top: var(--gap);

  @media (--tablet-) {
    display: grid;
    grid-gap: var(--gap);
    grid-template-columns: 1fr;
  }

  @media (--phone) {
    grid-gap: 0;
  }
}

.list {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  @media (--phone) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.listItem {
  max-width: 50%;
  padding: 0 7%;
  position: relative;
  text-align: left;

  &:not(:last-child)::after {
    background-color: var(--color-primary-400);
    content: '';
    height: rem(87px);
    max-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;

    @media (--phone) {
      bottom: 0;
      height: 2px;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
      width: 70%;
    }
  }

  @media (--tablet-) {
    flex-grow: 1;
  }

  @media (--phone) {
    max-width: none;
    padding: var(--gap);
    text-align: center;
  }
}

.icon {
  height: rem(115px);
  width: rem(115px);

  @media (--tablet-) {
    height: rem(80px);
    justify-self: center;
    width: rem(80px);
  }

  @media (--phone) {
    height: rem(60px);
    width: rem(60px);
  }
}

.mainPriceListItem {
  @media (--tablet-only) {
    text-align: right;
  }
}

.mainPrice {
  display: inline-block;
  font-family: var(--font-family);
  font-size: var(--text-2xl);
  font-size: min(5vw, var(--text-6xl));
  font-weight: 700;
  line-height: 1em;
  margin-bottom: var(--gap-small);

  @media (--phone) {
    font-size: var(--text-3xl);
  }
}

.subtitle,
.title,
.term {
  color: var(--color-primary-75);
  display: block;
  font-size: var(--text-m);
  font-weight: 400;
  line-height: 1em;
  margin: 0;
}

.title {
  margin: 0 0 12px;
}

.term {
  display: inline;
}

.subtitle {
  font-size: var(--text-xs);
}

.close {
  color: var(--color-primary-75);
  position: absolute;
  right: var(--gap);
  top: var(--gap);

  svg {
    height: var(--text-s);
    width: var(--text-s);
  }
}

.learnMoreBtn {
  align-self: flex-end;

  @media (--tablet-) {
    justify-self: center;
  }
}
