.wrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
}

.label {
  color: var(--color-neutral-900);
  font-size: var(--text-m);
  margin-bottom: var(--gap-small);
}

.description {
  color: var(--color-neutral-90);
  display: inline-block;
  margin-bottom: var(--gap-small);
}

.value {
  color: var(--color-neutral-900);
  font-size: var(--text-4xl);
  line-height: 1;

  &.boldValueTheme {
    font-weight: var(--medium);
  }

  @media (--tablet-) {
    font-size: var(--text-3xl);
  }
}
