.section {
  display: grid;
  gap: var(--gap-vertical-section);
  grid-template-columns: 1fr 1.4fr;

  @media (--tablet-) {
    grid-template-columns: 1fr;
    overflow-x: hidden;
  }
}

.title {
  color: var(--color-primary-500);
}

.link {
  margin-top: var(--gap-large);
}
