@media (--desktop) {
  .listItem:nth-child(even) {
    margin-top: 74px;
  }

  .list {
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;

    &:before {
      background-image: url('./images/bg.svg');
      background-position: rem(-15px) rem(55px);
      background-repeat: no-repeat;
      background-size: 100% 35%;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      transform: scale(1.2);

      width: 100%;
    }
  }

  .icon {
    background-color: var(--color-neutral-0);
    margin-bottom: var(--gap-large);
  }

  .middleIcon {
    background-color: transparent;
  }
}
