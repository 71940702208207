.cost {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.3em;
  display: flex;
  height: 118px;
  margin-bottom: var(--gap);
  padding: 15px 47px;

  @media (--desktop) {
    margin-bottom: 60px;
  }

  @media (--tablet-) {
    margin-bottom: 0;
  }

  @media (--phone) {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding: 27px 21px;
  }
}

.costValue {
  color: var(--color-primary-400);
  font-size: 50px;
  font-weight: 600;
  line-height: 34px;
  margin-right: var(--gap-small);

  @media (--phone) {
    margin: 0 0 var(--gap-small);
  }
}

.annualDetails {
  color: var(--color-neutral-100);
  font-size: var(--text-m);
  margin: 0;

  span {
    display: inline-block;
    line-height: var(--text-m);
  }
}

.monthly {
  color: var(--color-neutral-100);
  font-size: var(--text-s);
  font-weight: var(--bold);
  margin: 0;
}

.oldCost {
  font-size: 30px;
  opacity: 0.7;
  text-decoration: line-through;
}

.oldMonthlyCost {
  opacity: 0.7;
  text-decoration: line-through;
}

.accentColor {
  color: var(--color-accent-200);
}

.leftMargin {
  margin-left: 5px;
}
