.description {
  margin-bottom: var(--gap);
  position: relative;
}

.descriptionLink {
  position: relative;
  z-index: 1;
  @media (--desktop) {
    margin-top: auto;
  }

  @media (--phone) {
    margin-top: var(--gap);
  }
}

.globalLink {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
