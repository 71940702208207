.title {
  font-size: var(--text-xl);
  font-weight: var(--bold);
  margin-bottom: var(--gap-large);

  @media (--tablet-) {
    margin-bottom: var(--gap);
  }

  @media (--phone) {
    text-align: left;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--gap-large);
}

.listItem {
  flex-basis: 31%;

  @media (--tablet-) {
    flex-basis: 100%;

    &:not(:last-child) {
      margin-bottom: var(--gap-huge);
    }
  }
}

.description {
  font-size: var(--text-xs);
}

.icon {
  height: rem(40px);
  width: rem(40px);
}

.costWrapper {
  margin-bottom: var(--gap);
}
