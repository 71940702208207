.section {
  padding-bottom: 0;
  padding-top: 0;
}

.mainSwiperSection {
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: var(--color-accent-primary);
  position: relative;

  @media (--desktop) {
    background-image: url('./bg-decor-2.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::before {
    background-image: url('~icons/dot-bg-primary-75.svg');
    bottom: 0;
    content: '';
    height: 259px;
    left: -5%;
    position: absolute;
    width: 204px;
    z-index: 1;
  }
}

.mainSwiperContainer {
  background-color: var(--color-primary-50);
  margin-bottom: var(--gap-title);

  @media (--desktop) {
    &::after {
      background: var(--line-bg);
      content: '';
      display: block;
      height: 20px;
      width: 100%;
    }
  }
}

.descriptionSliderItem {
  font-size: var(--text-m);
  margin-bottom: var(--gap-vertical-section);

  @media (--tablet) {
    font-size: var(--text-l);
    padding: 0 49px;
  }

  @media (--desktop) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    justify-content: space-between;
  }
}

.navigation {
  background-color: red;
}
