.section {
  padding-bottom: var(--gap-title);
  position: relative;
  text-align: center;
}

.title {
  color: var(--color-primary-400);
}

.container {
  background-color: var(--color-primary-50);
}
