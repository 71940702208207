.welcomeImage {
  @media (--desktop) {
    margin-bottom: calc(var(--welcome-image-bottom-offcet) - 3%);
  }
  position: relative;
  z-index: 2;
}

.mapWrapper {
  background: linear-gradient(100.65deg, rgba(0, 0, 0, 0.2) -3.07%, rgba(0, 0, 0, 0) 69.08%),
    var(--color-primary-400);
}

.footer {
  margin-top: var(--gap-large);

  & > * {
    margin-bottom: var(--gap-small);
  }
}

.primaryButton {
  margin-right: var(--gap-button);
}
