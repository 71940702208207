.blocked::after {
  background-image: url('../images/blocked-icon.svg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 1.05em;
  margin-left: var(--gap-small);
  width: 1.05em;
}

.wrapper {
  align-items: flex-start;
  display: flex;
  gap: var(--gap-small);
  text-align: left;
}

.helpTooltip {
  bottom: -0.1rem;
  margin-left: var(--gap-tiny);
  position: relative;
}

.icon {
  color: var(--color-primary-400);
  flex-shrink: 0;
  height: rem(60px);
  margin-right: var(--gap-small);
  width: rem(60px);
}

.maxValue {
  margin-right: var(--gap-small);
}

.editBtn {
  align-items: center;
  color: var(--color-accent-200);
  display: inline-flex;
  font-size: var(--text-xs);
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  padding: 10px;
  position: relative;
  width: fit-content;

  &:hover {
    color: var(--color-accent-200);
  }

  svg {
    margin-right: var(--gap-tiny);
  }
}

.highlightedValue {
  color: var(--color-accent-200);
}

.leftSection {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.editBtnWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-tiny);
}
