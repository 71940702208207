.trustpilotWidget {
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  padding: 15px 0;
  transition: all 1s;
  visibility: hidden;
  width: 100%;

  > iframe {
    max-width: var(--width-container);
  }

  @media (--phone) {
    background-color: #f1f1f1;
  }
}
