.field {
  margin-bottom: 0;
  max-width: var(--max-content-width);

  @media (--phone) {
    max-width: 100%;
    width: 100%;
  }
}

.button {
  @media (--tablet-only) {
    margin-left: var(--gap);
    margin-top: 0;
    min-height: rem(55px);
  }

  @media (--phone) {
    width: 100%;
  }
}

.form {
  margin-top: var(--gap-huge);
  position: relative;
  z-index: 1;

  @media (--tablet-only) {
    align-items: flex-start;
    display: flex;
    margin-bottom: var(--gap-large);
  }

  @media (--phone) {
    margin: 0 auto;
    max-width: 76%;
    min-width: rem(250px);
  }
}

.content {
  margin-bottom: var(--gap);
}
