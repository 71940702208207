.svg {
  --state-stroke: var(--color-neutral-40);
  position: relative;
  width: 100%;
  z-index: 1;

  @media (--phone) {
    display: none;
  }
}

.state {
  fill: transparent;
  stroke: var(--state-stroke);
  stroke-width: 2;
}

@keyframes __bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mapPin {
  display: block;
  opacity: 0;
  outline: none;
  position: relative;
  transform: translateY(-10px);

  [data-is-map-view='true'] & {
    animation: __bounceInDown 1s ease forwards;
    animation-delay: var(--transition-delay);
  }

  circle {
    color: var(--color-accent-primary);
  }
}

.homePageMapPin {
  cursor: pointer;
  display: block;
  fill: currentColor;
  opacity: 0;
  outline: none;
  position: relative;
  transform: translateY(-10px);

  &[data-is-group='false'] {
    cursor: pointer;
  }

  [data-is-map-view='true'] & {
    animation: __bounceInDown 1s ease forwards;
    animation-delay: var(--transition-delay);
  }

  circle {
    color: var(--color-accent-primary);
  }
}
/* stylelint-disable declaration-no-important */
.tooltip {
  background-color: var(--color-neutral-0) !important;
  border: 1px solid var(--color-primary-100) !important;
  border-radius: 10px !important;
  box-shadow: 0 11px 12px -10px rgba(0, 0, 0, 0.25);
  color: var(--color-primary-400) !important;
  font-size: var(--text-s) !important;
  font-weight: 500 !important;
  line-height: 1.2em;
  padding: 2px 26px !important;
  white-space: nowrap;

  &:global(.show) {
    opacity: 1 !important;
    visibility: visible;
  }

  &:after {
    display: none;
  }
}
/* stylelint-enable declaration-no-important */
