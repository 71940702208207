.container {
  background: linear-gradient(100.65deg, rgba(0, 0, 0, 0.2) -3.07%, rgba(0, 0, 0, 0) 69.08%),
    #6ea8ba;
}

.content {
  margin-bottom: var(--gap-large);
  @media (--desktop) {
    max-width: var(--max-content-width);
  }
}

.section {
  --max-content-width: rem(450px);
  color: var(--color-neutral-0);
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: 1fr 1fr;

  &::before {
    background-image: url('~icons/dot-bg-tertiary-75.svg');
    background-position: right bottom;
    bottom: 0;
    content: '';
    height: 109px;
    position: absolute;
    right: 0;
    width: 250px;
    z-index: 0;

    @media (--tablet-) {
      height: 217px;
      width: 120px;
    }

    @media (--phone) {
      width: 200px;
    }
  }

  @media (--tablet-) {
    grid-template-columns: 1fr;
  }
}

.leftSectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: inherit;

  @media (--phone) {
    margin-left: auto;
    margin-right: auto;
    max-width: rem(300px);
  }
}

.link {
  color: var(--color-neutral-0);
  margin-top: 25px;

  @media (--phone) {
    max-width: 76%;
  }

  &:hover {
    color: var(--color-neutral-0);

    @media (--phone) {
      background-color: transparent;
    }
  }
}
